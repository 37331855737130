








import { Component, Prop, Vue } from 'vue-property-decorator'

// types
import { UserRoleResource } from '@/store/types'

@Component
export default class UserProfileListRights extends Vue {
  @Prop({ required: true })
  private userRoles!: UserRoleResource[]
}
