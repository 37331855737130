












import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
import { LogResource } from '@/store/types'

@Component({
  components: {
    Tag,
  },
})
export default class UserProfileListActions extends Vue {
  @Prop({ required: true })
  private logs!: LogResource[]
}
