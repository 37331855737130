





































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import BoughtCoursesListCard from '@/components/cards/BoughtCoursesListCard.vue'
import UserProfileListRights from '@/components/views/users/UserProfileListRights.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import UserProfileListActions from '@/components/views/users/UserProfileListActions.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// types
import {
  LogResource,
  NameValueResource,
  SocialResource,
  UserBoughtGroupResource,
  UserManagerProfile,
  UserManagerResource,
  UserRoleResource,
} from '@/store/types'
// store
import ManagerUsersModule from '@/store/modules/manager/users'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    Autocomplete,
    BoughtCoursesListCard,
    DateInput,
    Select,
    TextInput,
    UserProfileListActions,
    UserProfileListRights,
    UserSocialCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class UserInformationForm extends Mixins(NotifyMixin) {
  @Prop({ default: null })
  private user!: UserManagerResource

  @Prop({ default: false })
  private readonly!: boolean

  private isLoaded = false
  private logs: LogResource[] = []
  private userCourses: UserBoughtGroupResource[] = []
  private form: UserManagerProfile = {
    birthDate: '',
    email: '',
    name: '',
    phone: '',
    roleIds: [],
    socialIds: [],
    surname: '',
    timezoneIds: [],
  }

  // в зависимости от этого значения меняется отображение формы для мастера и сотрудника
  private get isMasterPage () {
    return !!this.$route.params.masterUUID
  }
  // в зависимости от этого значения меняется отображение формы для мастера и сотрудника
  private get isMentorPage () {
    return !!this.$route.params.mentorUUID
  }

  private get cities () {
    return DictionaryModule.cities
  }

  private get timezones () {
    return DictionaryModule.timezones
  }

  private get roles () {
    return DictionaryModule.roles
  }

  private mounted () {
    DictionaryModule.fetchCities()

    DictionaryModule.fetchTimezones()

    if (this.user) {
      this.form = {
        birthDate: this.user.birthDate,
        cityId: this.user.city ? +this.user.city.value : undefined,
        email: this.user.email,
        name: this.user.name,
        phone: this.user.phone,
        roleIds: this.user.roles.map((role: UserRoleResource) => role.id),
        socialIds: this.user.socials.map((social: SocialResource) => social.id),
        surname: this.user.surname,
        timezoneIds: this.user.timezones.map((timezone: NameValueResource) => +timezone.value),
      }

      if (this.isMasterPage) { // Для мастера необходимы логи и заказы
        ManagerUsersModule.fetchUserLogs(this.user.id)
          .then((logs: LogResource[]) => {
            this.logs = [...logs]
            ManagerUsersModule.fetchUserGroup(this.user.id)
              .then((response: UserBoughtGroupResource[]) => {
                this.userCourses = [...response]
              })
              .catch(this.notifyError)
              .finally(() => this.isLoaded = true)
          })
          .catch(this.notifyError)
      } else this.isLoaded = true
    }
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerUsersModule.saveUser({
            params : this.form,
            userUUID: this.user.uuid,
          })
            .then(() => {
              requestAnimationFrame(() => (form.reset()))
              this.notifySuccess('Данные сохранены')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private pastePhone(phone: string) {
    if (!phone) return
    setTimeout(() => {
      this.form.phone = phone.replace(/^\+?7/m, '8')
    })
  }
}
